<template>
	<div class="border">
    <div class="loan-row">
			<div class="item">
				<el-form-item prop="loanPlatformId" :rules="{required: true, message: '请填写该项', trigger: 'blur'}">
			    <v-autocomplete
            v-model="loanInfoForm.loanPlatformId"
            :items="loanPlatformList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="贷款平台"
            item-text="loanPlatformName"
            item-value="id"
            outlined
            :dense="true"
            :height="42"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
            no-data-text="暂无数据"
          ></v-autocomplete>
				</el-form-item>
			</div>
			<div class="item">
				<el-form-item prop="loanIouNo" :rules="{required: true, message: '请填写该项', trigger: 'blur'}"><v-text-field label="贷款借据号" v-model="loanInfoForm.loanIouNo" outlined :dense="true" :height="42"></v-text-field></el-form-item>
			</div>
      <div class="item">
        <el-form-item prop="loanRefundTime" style="width:282px;margin-bottom:0;" :rules="{required: true, message: '请填写该项', trigger: 'blur'}">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="loanInfoForm.loanRefundTime"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="loanInfoForm.loanRefundTime"
                label="退贷日期"
                hide-details="auto"
                outlined
                :dense="true"
                :height="42"
                :clearable="true"
                v-bind="attrs"
                v-on="on"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker v-model="loanInfoForm.loanRefundTime" no-title locale="zh-cn">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">取消</v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(loanInfoForm.loanRefundTime)"
              >
                确认
              </v-btn>
            </v-date-picker>
          </v-menu>
        </el-form-item>
      </div>
		</div>
    <div class="loan-row">
      <div class="item">
				<el-form-item prop="loanRefundType" :rules="{required: true, message: '请填写该项', trigger: 'blur'}">
				  <v-autocomplete
            v-model="loanInfoForm.loanRefundType"
            :items="[{name: '全部退款', id: 1}, {name: '降贷', id: 2}]"
            :menu-props="{ bottom: true, offsetY: true }"
            label="退贷类型"
            item-text="name"
            item-value="id"
            outlined
            :dense="true"
            :height="42"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
            no-data-text="暂无数据"
          ></v-autocomplete>
				</el-form-item>
			</div>
      <div class="item">
				<el-form-item prop="loanRefundMoney" :rules="{required: true, message: '请填写该项', trigger: 'blur'}">
          <v-text-field label="贷款退费" v-model="loanInfoForm.loanRefundMoney" autocomplete = "off" outlined :dense="true" :height="42" type="number" @change="loanRefundMoneyVerify" suffix="元"></v-text-field>
        </el-form-item>
			</div>
      <div class="item">
				<el-form-item prop="loanOccupiedinterestMoney" :rules="{required: true, message: '请填写该项', trigger: 'blur'}">
          <v-text-field label="占用贴息" :value="loanInfoForm.loanOccupiedinterestMoney" disabled outlined :dense="true" :height="42" suffix="元"></v-text-field>
        </el-form-item>
			</div>
      <el-tooltip effect="light" placement="top-start">     
        <div slot="content">1、公司贴息，请在退费计算中添加扣费项目【利息支出】并扣费，占用贴息 = 利息支出扣费总和<br/>
                            2、学员付息，不需要添加【利息支出】扣费，占用贴息 = 0</div>
        <i class="el-icon-question" style="font-size: 20px;color:#9BACBF"></i>
      </el-tooltip>
    </div>
	</div>
</template>

<script>
import { installmentModeEnum, OrderOriginTypeEnum, refundTypeEnum } from '@/views/order/constants'
const rulesList = ['loanCompanyName', 
'loanBankName', 'loanBankAccount', 
'loanIouNo', 'loanRefundTime', 
'loanRefundMoney', 'loanReturnedMoney', 'loanRefundType', 
'loanPlatformId', 'loanOccupiedinterestMoney']
export default {
	props: {
		loanInfoForm: Object,
		rulesForm: Object,//填充数据以便校验
		installmentMode: Number,
		loanRefundStuMoney: [Number, String],
		rules: Object,
		type: {
			type: String,
			default: 'dropOut'
		},
		loanIouNoDisabled: {
			type: Boolean,
			default: false
		},
		orderTable: Array,
    loanPlatformList: Array,
    loanRefundMoneyVerify: Function
	},
	data(){
		return {
			installmentModeEnum,
			OrderOriginTypeEnum,
      menu: false
		}
	},
	watch: {
		loanInfoForm: {
			deep: true,
			immediate: true,
			handler(loanInfoForm){
				if(this.rulesForm){
					rulesList.map(key =>{
						this.rulesForm[key] = loanInfoForm[key]
					})
				}
			}
		}
	},
	computed: {
		orderOriginType(){
			return this.orderTable[0] ? this.orderTable[0].orderOriginType : OrderOriginTypeEnum.ty
		}
	},
	methods: {}
}
</script>
<style lang="scss" scoped>
.label{
	width: 100px;
	flex: 0 0 100px;
}
.loan-row{
	display: flex;
	align-items: center;
	white-space: nowrap;
	height: 42px;
	margin-bottom: 28px;
	.el-date-editor{
		width: 100% !important;
		height: 42px;
	}
	::v-deep .el-form-item.is-error .el-input__inner{
		border-color: #C0C4CC;
	}
}
.item{
	width: 282px;
	flex: 0 0 282px;
	margin-right: 24px;
	.el-form-item{
		margin-bottom: 0;
	}
}

.border {
    margin-top: 10px;
    border: 1px solid #E1E4EB;
    padding: 18px 18px 0;
    margin-bottom: 12px;
  }

</style>

